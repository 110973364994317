import { DateTime } from "luxon";
import { LongTermDisabilityPolicyCoverage, OccupationPeriodEnum, PolicyCoverage } from "../generated/graphql";

export function findOwnOccupationEndDateFor(product: PolicyCoverage,
                                            benefitStartDate: DateTime,
                                            benefitEndDate: DateTime): DateTime | null {
    let ownOccupationPeriod = (product as LongTermDisabilityPolicyCoverage).configuration.ownOccupationPeriod
    if(!ownOccupationPeriod) {
        return null;
    }
    if(ownOccupationPeriod === OccupationPeriodEnum.None) {
        return benefitStartDate
    } else if(ownOccupationPeriod === OccupationPeriodEnum.EndOfBenefitDuration) {
        return benefitEndDate
    }
    let years = 0;
    if(ownOccupationPeriod === OccupationPeriodEnum.OneYear) {
        years = 1
    } else if(ownOccupationPeriod === OccupationPeriodEnum.TwoYear) {
        years = 2
    } else if(ownOccupationPeriod === OccupationPeriodEnum.ThreeYear) {
        years = 3
    } else if(ownOccupationPeriod === OccupationPeriodEnum.FiveYear) {
        years = 5
    } else if(ownOccupationPeriod === OccupationPeriodEnum.TenYear) {
        years = 10
    }
    return benefitStartDate.plus({ years });
}
import { gql } from '@apollo/client';

export const hospitalIndemnityBenefitQueryPart = `
                        hospitalIndemnityChoice {
                            coverages {
                                bookId
                                planId
                                configuration {
                                    planType
                                    preExistingConditions {
                                        included
                                    }
                                    provisions {
                                        maternity
                                        healthScreening {
                                            included
                                        }
                                        firstDayHospitalSickness {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        firstDayHospitalInjury {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        firstDayIntensiveCareUnitSickness {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        firstDayIntensiveCareUnitInjury {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        dailyHospitalSickness {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        dailyHospitalInjury {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        dailyIntensiveCareUnitSickness {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        dailyIntensiveCareUnitInjury {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        newbornNursery {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        rehabilitationFacility {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        skilledNursingFacility {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        hospiceCare {
                                            included
                                            amount
                                            maximumDaysPerLifetime
                                        }
                                        mentalDisorderTreatmentFacility {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        substanceAbuseTreatmentFacility {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        mentalDisorderOutpatientTherapy {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        substanceAbuseOutpatientTherapy {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        physicianVisit {
                                            inOfficeIncluded
                                            inOfficeAmount
                                            telemedicineIncluded
                                            telemedicineAmount
                                            daysPerPersonPerYear
                                        }
                                        durableMedicalEquipment {
                                            tierOneIncluded
                                            tierOneAmount
                                            tierTwoIncluded
                                            tierTwoAmount
                                            tierThreeIncluded
                                            tierThreeAmount
                                            daysPerYear
                                        }
                                        therapyServices {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        chiropracticCare {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        airAmbulance {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        groundOrWaterAmbulance {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        emergencyRoomSickness {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        emergencyRoomInjury {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        observationUnit {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        urgentCareFacility {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        inpatientSurgery {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        outpatientSurgeryAmbulatory {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        outpatientSurgeryPhysiciansOffice {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        generalAnesthesia {
                                            included
                                            amount
                                        }
                                        labTestOrXray {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        majorDiagnosticProcedure {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        invasiveDiagnosticProcedure {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        bloodProducts {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        secondOpinion {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        medicalTravel {
                                            included
                                            amount
                                            roundTripsPerYear
                                        }
                                        companionLodging {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        doulaCare {
                                            included
                                            amount
                                            visitsPerPregnancy
                                        }
                                        familyCare {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        petCare {
                                            included
                                            amount
                                            daysPerYear
                                        }
                                        homeHealthServices {
                                            included
                                            amount
                                            maximumDaysPerLifetime
                                        }
                                        prescriptionDrug {
                                            brandNameIncluded
                                            brandNameAmount
                                            genericIncluded
                                            genericAmount
                                            daysPerYear
                                        }
                                    }
                                    spouseBenefitPercentageOfEmployee
                                    childBenefitPercentageOfEmployee
                                    portability
                                }
                            }
                        }`

export const accidentBenefitQueryPart = `
                        accident {
                            bookId
                            configuration {
                                portability
                                wellnessBenefitAmount
                                includesAccidentalDeathAndDismemberment
                                benefitSchedule {
                                    groups {
                                        type
                                        requiresAccidentalDeathAndDismembermentCoverage
                                        benefitsPayable {
                                            type
                                            employeeAmount
                                            spouseAmount
                                            childAmount
                                        }
                                    }
                                }
                            }
                        }`

export const criticalIllnessBenefitQueryPart = `
                        criticalIllness {
                            bookId
                            configuration {
                                portability
                                wellnessBenefitAmount
                                cancerOneTwo
                                benefitSchedule {
                                    benefitsPayable {
                                        type
                                        percentOfScheduleBenefit {
                                            firstOccurrence
                                            additionalOccurrence
                                        }
                                        flatAmount {
                                            firstOccurrence
                                            additionalOccurrence
                                        }
                                        isChildhoodOnlyCondition
                                        requiresCancerOneTwo
                                    }
                                    childPercentOfMemberScheduleBenefit
                                }
                            }
                        }`

export const lifeBenefitQueryPart = `
                        groupTermLife {
                            bookId
                            accidentalDeathAndDismemberment {
                                isStandardIncluded
                            }
                            configuration {
                                acceleratedBenefits
                                ageReductions {
                                    age
                                    reductionPercentage
                                }
                            }
                            dependent {
                                childUnderSixMonthsAmount
                                childSixMonthsAndOverAmount
                                spouseAmount
                            }
                        }`

export const longTermDisabilityBenefitQueryPart = `
                        longTermDisability {
                            bookId
                            configuration {
                                preExistingConditions {
                                    included
                                    beforeEffectiveDatePeriod
                                    beforeEffectiveDateCadence
                                    disabilityBeginsWithinPeriod
                                    disabilityBeginsWithinCadence
                                    afterEffectiveDateWithoutTreatmentPeriod
                                    afterEffectiveDateWithoutTreatmentCadence
                                }
                                benefitPercent
                                minimumBenefit
                                maximumBenefit
                                eliminationPeriod
                                eliminationPeriodMode
                                benefitDuration
                                ownOccupationPeriod
                            }
                            amountType
                        }`;

export const paidFamilyLeaveBenefitQueryPart = `
                        paidFamilyLeave {
                            bookId
                            configuration {
                                benefitSchedule {
                                    groups {
                                        type
                                        benefitsPayableDescription
                                        sharedTimeGroups {
                                            type
                                        }
                                        timeEntitlement
                                        timeBasis
                                        timeWithinPeriod
                                        timeWithinPeriodBasis
                                    }
                                }
                            }
                        }`;

export const paidMedicalLeaveBenefitQueryPart = `
                        paidMedicalLeave {
                            bookId
                            configuration {
                                benefitSchedule {
                                    groups {
                                        type
                                        benefitsPayableDescription
                                        sharedTimeGroups {
                                            type
                                        }
                                        timeEntitlement
                                        timeBasis
                                        timeWithinPeriod
                                        timeWithinPeriodBasis
                                    }
                                }
                            }
                        }`;

export const shortTermDisabilityBenefitQueryPart = `
                        shortTermDisability {
                            bookId
                            configuration {
                                preExistingConditions {
                                    included
                                    beforeEffectiveDatePeriod
                                    beforeEffectiveDateCadence
                                    disabilityBeginsWithinPeriod
                                    disabilityBeginsWithinCadence
                                    afterEffectiveDateWithoutTreatmentPeriod
                                    afterEffectiveDateWithoutTreatmentCadence
                                }
                                maternity
                                flatAmount
                                sixtySixPercentLimit
                                benefitPercent
                                minimumBenefit
                                maximumBenefit
                                accidentEliminationPeriod
                                sicknessEliminationPeriod
                                maximumBenefitPeriod
                                firstDayHospital
                            }
                            amountType
                        }`

export const voluntaryTermLifeBenefitQueryPart = `
                        voluntaryTermLife {
                            bookId
                            accidentalDeathAndDismemberment {
                                isStandardIncluded
                            }
                            configuration {
                                acceleratedBenefits
                                portability
                                ageReductions {
                                    age
                                    reductionPercentage
                                }
                            }
                            spouse {
                                accidentalDeathAndDismemberment {
                                    isStandardIncluded
                                }
                                ageReductions {
                                    age
                                    reductionPercentage
                                }
                            }
                        }`

export const FIND_MEMBER_QUERY = gql`
    query findMember($benefitKey: String!, $coverage: Coverage!) {
        findMember(benefitKey: $benefitKey, coverage: $coverage) {
            firstName
            lastName
            birthDate
            policyNumber
            privacyId
            benefit {
                contractState
                status
                effectiveDate
                terminationDate
                approvedAmount
                pendingAmount
                accidentalDeathAndDismembermentApprovedAmount
                accidentalDeathAndDismembermentPendingAmount
                employeeAssistanceProgramProvision
                evidenceOfInsurabilityStatus
                evidenceOfInsurabilityUrl
            }
            dependents {
                firstName
                lastName
                relationship
                birthDate
                benefit {
                    status
                    terminationDate
                    approvedAmount
                    pendingAmount
                    accidentalDeathAndDismembermentApprovedAmount
                    accidentalDeathAndDismembermentPendingAmount
                    evidenceOfInsurabilityStatus
                    evidenceOfInsurabilityUrl
                }
            }
            employment {
                workState
                wage
                wageFrequency
                hoursWorkedPerWeek
                status
            }
            sourceSystem
        }
    }
`;

export const FIND_HOSPITAL_INDEMNITY_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${hospitalIndemnityBenefitQueryPart}
        }
    }
`;

export const FIND_ACCIDENT_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${accidentBenefitQueryPart}
        }
    }
`;

export const FIND_LIFE_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${lifeBenefitQueryPart}
        }
    }
`;

export const FIND_LONG_TERM_DISABILITY_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${longTermDisabilityBenefitQueryPart}
        }
    }
`;

export const FIND_PAID_FAMILY_LEAVE_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${paidFamilyLeaveBenefitQueryPart}
        }
    }
`;

export const FIND_PAID_MEDICAL_LEAVE_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${paidMedicalLeaveBenefitQueryPart}
        }
    }
`;

export const FIND_SHORT_TERM_DISABILITY_QUERY = gql`
    query findMemberGroup($benefitKey: String!) {
        findMemberGroup(benefitKey: $benefitKey) {
            ${shortTermDisabilityBenefitQueryPart}
        }
    }
`;

export const FIND_ANNOUNCEMENTS_QUERY = gql`
    query findAnnouncements($coverage: Coverage!) {
        findAnnouncements(coverage: $coverage) {
            title
            announcements {
                message
            }
        }
    }
`;

export const FIND_CLAIMS_QUERY = gql`
    query findClaims($coverage: Coverage!) {
        findClaims(coverage: $coverage) {
            claimId
            createdBy
            assignedUserId
            notificationDate
            occupation {
                hoursWorkedPerWeek
            }
            benefits {
                type
                employerContributionPercentage
                premiumPaymentType
                memberStatusIndicator
                statuses {
                    effectiveDate
                }
                payments {
                    paymentId
                    netAmount
                    currentStatus {
                        type
                        effectiveDate
                    }
                    edfEmployeeEobDocumentInstanceId
                    itemizations {
                        paymentAmount
                        name
                    }
                }
            }
            coveredPerson {
                firstName
                dependentType
            }
            documents {
                name
                sharepointItemId
                creationDate
            }
        }
    }
`;

export const FIND_CLAIM_ANALYST_QUERY = gql`
    query findClaimAnalyst($claimId: String!) {
        findClaimAnalyst(claimId: $claimId) {
            firstName
            phoneNumber
            emailAddress
        }
    }
`;

export const FIND_BENEFIT_DOCUMENT_QUERY = gql`
    query findBenefitDocument($benefitKey: String!, $coverage: Coverage!, $documentType: BenefitDocumentType!) {
        findBenefitDocument(benefitKey: $benefitKey, coverage: $coverage, documentType: $documentType) {
            content
        }
    }
`;

export const FIND_EXPLANATION_OF_BENEFITS_QUERY = gql`
    query findExplanationOfBenefits($claimId: String!, $coverage: Coverage!, $paymentId: String!) {
        findExplanationOfBenefits(claimId: $claimId, coverage: $coverage, paymentId: $paymentId) {
            content
        }
    }
`;

export const FIND_CLAIM_DOCUMENT_QUERY = gql`
    query findClaimDocument($claimId: String!, $sharepointItemId: String!) {
        findClaimDocument(claimId: $claimId, sharepointItemId: $sharepointItemId) {
            content
            name
        }
    }
`;

export const UPLOAD_CLAIM_DOCUMENT_MUTATION = gql`
    mutation uploadClaimDocument($uploadDocumentInput: UploadDocumentInput!, $file: Upload!) {
        uploadClaimDocument(uploadDocumentInput: $uploadDocumentInput, file: $file) {
            name
            sharepointItemId
            creationDate
        }
    }
`;

import { QueryResult } from "@apollo/client/react/types/types";
import { useBenefitKeyQuery } from "./useBenefitKeyQuery";
import { useCoverage } from "./useCoverage";
import { gql } from "@apollo/client";
import { Coverage } from "../enums/Coverage";
import findBenefitServiceQueryPartFor = Coverage.findBenefitServiceQueryPartFor;
import findBenefitServiceNameFor = Coverage.findBenefitServiceNameFor;

export function useProduct(): QueryResult {
    const coverage = useCoverage();
    const benefitServiceCoverage = findBenefitServiceNameFor(coverage);
    const queryResult = useBenefitKeyQuery(gql`
        query findMemberGroup($benefitKey: String!) {
            findMemberGroup(benefitKey: $benefitKey) {
                ${findBenefitServiceQueryPartFor(coverage)}
            }
        }
    `, {
        skip: !benefitServiceCoverage
    })
    if(!benefitServiceCoverage) {
        return { loading: false, data: {} } as QueryResult
    }
    const product = queryResult.data?.findMemberGroup?.[benefitServiceCoverage];
    const data = product?.coverages ? product?.coverages?.[0] : product;
    return { ...queryResult, data };
}

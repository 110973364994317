import { DateTime } from "luxon";
import { BenefitDurationEnum, LongTermDisabilityPolicyCoverage, ShortTermDisabilityPolicyCoverage } from "../generated/graphql";

export function findBenefitEndDateFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                      birthDate: DateTime, disabilityDate: DateTime, benefitStartDate: DateTime): DateTime {
    const benefit = new Benefit(birthDate, disabilityDate);
    const maximumBenefitPeriod = (product as ShortTermDisabilityPolicyCoverage).configuration.maximumBenefitPeriod
    if (maximumBenefitPeriod) {
        return addDays(benefitStartDate, 7 * maximumBenefitPeriod - 1)
    }
    let benefitDuration = (product as LongTermDisabilityPolicyCoverage).configuration.benefitDuration;
    const errorMessage = `invalid product. maximum benefit period: ${maximumBenefitPeriod}, benefit duration: ${benefitDuration}`
    if(!benefitDuration) {
        throw new Error(errorMessage);
    }
    if (benefitDuration === BenefitDurationEnum.TwoYear) {
        return findTwoYearBenefitEndDateFor(benefit, benefitStartDate)
    } else if (benefitDuration === BenefitDurationEnum.FiveYear) {
        return findFiveYearBenefitEndDateFor(benefit, benefitStartDate)
    } else if (benefitDuration === BenefitDurationEnum.ReducingBenefitDuration) {
        return findReducingBenefitEndDateFor(benefit, benefitStartDate)
    } else if (benefitDuration === BenefitDurationEnum.SixtyFiveSlashFiveSlashSeventy) {
        return findSixtyFiveFiveSeventyBenefitEndDateFor(benefit, benefitStartDate)
    } else if (benefitDuration === BenefitDurationEnum.SocialSecurityNormalRetirementAge) {
        return findSocialSecurityNormalRetirementAgeBenefitEndDateFor(benefit, benefitStartDate)
    }
    throw new Error(errorMessage);
}

function findTwoYearBenefitEndDateFor(benefit: Benefit, calculatedStartDate: DateTime) {
    if ((benefit.getAgeOfClaimantAtIncurredDate() >= 69) || (after(addDays(addMonths(calculatedStartDate, 12), -1), benefit.getDateWhenClaimantIsAge(70))))
        return addDays(addMonths(calculatedStartDate, 12), -1)
    else
        return DateTime.min(addDays(addMonths(calculatedStartDate, 24), -1), benefit.getDateWhenClaimantIsAge(70))
}

function findFiveYearBenefitEndDateFor(benefit: Benefit, calculatedStartDate: DateTime) {
    if (benefit.getAgeOfClaimantAtIncurredDate() >= 62)
        return DateTime.max(benefit.getDateWhenClaimantIsAge(65), addDays(addMonths(calculatedStartDate, benefitEndDateByClaimantAgeLookup(benefit.getAgeOfClaimantAtIncurredDate())), -1))
    else if (after(addDays(addMonths(calculatedStartDate, 42), -1), benefit.getDateWhenClaimantIsAge(65)))
        return addDays(addMonths(calculatedStartDate, 42), -1)
    else
        return DateTime.min(addDays(addMonths(calculatedStartDate, 60), -1), benefit.getDateWhenClaimantIsAge(65))
}

function findReducingBenefitEndDateFor(benefit: Benefit, calculatedStartDate: DateTime) {
    if (benefit.getAgeOfClaimantAtIncurredDate() <= 62)
        return DateTime.max(benefit.getDateWhenClaimantIsAge(65), addDays(addMonths(calculatedStartDate, benefitEndDateByClaimantAgeLookup(benefit.getAgeOfClaimantAtIncurredDate())), -1))
    else
        return addDays(addMonths(calculatedStartDate, benefitEndDateByClaimantAgeLookup(benefit.getAgeOfClaimantAtIncurredDate())), -1)
}

function findSixtyFiveFiveSeventyBenefitEndDateFor(benefit: Benefit, calculatedStartDate: DateTime) {
    const age = benefit.getAgeOfClaimantAtIncurredDate();
    if (age < 61)
        return DateTime.max(benefit.getDateWhenClaimantIsAge(65), addDays((addMonths(calculatedStartDate, 60)), -1))
    else if ((age >= 61) && (age < 69 || calcAgeAtBSDRule(benefit, calculatedStartDate) < 69))
        return DateTime.min(addDays(addMonths(calculatedStartDate, 60), -1), benefit.getDateWhenClaimantIsAge(70))
    else if (age >= 69)
        return addDays(addMonths(calculatedStartDate, 12), -1)
    else if (after(calculatedStartDate, benefit.getDateWhenClaimantIsAge(69)))
        return addDays(addMonths(calculatedStartDate, 12), -1)
    throw new Error('invalid age of claimant')
}

function findSocialSecurityNormalRetirementAgeBenefitEndDateFor(benefit: Benefit, calculatedStartDate: DateTime) {
    const birthDate = benefit.getClaimantDateOfBirth();
    const age = benefit.getAgeOfClaimantAtIncurredDate();
    const monthsToRetirement = benefitEndDateNumberOfMonthsToRetirementLookup(year(birthDate));
    const retirementMonth = addMonths(birthDate, monthsToRetirement);
    if (age < 65)
        return DateTime.max(addDays(addMonths(calculatedStartDate, 36), -1), retirementMonth)
    else
        return DateTime.max(addDays(addMonths(calculatedStartDate, pfgBenefitEndDateByClaimantAgeLookup(age)), -1), retirementMonth)
}

class Benefit {
    private birthDate: DateTime;
    private disabilityDate: DateTime;

    constructor(birthDate: DateTime, disabilityDate: DateTime) {
        this.birthDate = birthDate;
        this.disabilityDate = disabilityDate;
    }

    public getClaimantDateOfBirth(): DateTime {
        return this.birthDate;
    }

    public getAgeOfClaimantAtIncurredDate(): number {
        return dateDiffYears(this.disabilityDate, this.birthDate)
    }

    public getDateWhenClaimantIsAge(age: number): DateTime {
        return this.birthDate.plus({ years: age });
    }
}

function addDays(dateTime: DateTime, days: number): DateTime {
    return dateTime.plus({ days })
}

function addMonths(dateTime: DateTime, months: number) {
    return dateTime.plus({ months })
}

function year(dateTime: DateTime): number {
    return dateTime.year
}

function after(dateTime1: DateTime, dateTime2: DateTime): boolean {
    return dateTime1 > dateTime2
}

function benefitEndDateByClaimantAgeLookup(ageAtDisability: number): number {
    if (ageAtDisability <= 62) {
        return 42
    } else if (ageAtDisability === 63) {
        return 36
    } else if (ageAtDisability === 64) {
        return 30
    } else if (ageAtDisability === 65) {
        return 24
    } else if (ageAtDisability === 66) {
        return 21
    } else if (ageAtDisability === 67) {
        return 18
    } else if (ageAtDisability === 68) {
        return 15
    }
    return 12
}

function benefitEndDateNumberOfMonthsToRetirementLookup(birthYear: number): number {
    if (birthYear < 1938) {
        return 780
    } else if (birthYear === 1938) {
        return 782
    } else if (birthYear === 1939) {
        return 784
    } else if (birthYear === 1940) {
        return 786
    } else if (birthYear === 1941) {
        return 788
    } else if (birthYear === 1942) {
        return 790
    } else if (birthYear >= 1943 && birthYear <= 1954) {
        return 792
    } else if (birthYear === 1955) {
        return 794
    } else if (birthYear === 1956) {
        return 796
    } else if (birthYear === 1957) {
        return 798
    } else if (birthYear === 1958) {
        return 800
    } else if (birthYear === 1959) {
        return 802
    }
    return 804
}

function pfgBenefitEndDateByClaimantAgeLookup(ageAtDisability: number): number {
    if (ageAtDisability < 65) {
        throw new Error('age must be greater than or equal to 65')
    } else if (ageAtDisability >= 65 && ageAtDisability <= 67) {
        return 24
    } else if (ageAtDisability >= 68 && ageAtDisability <= 69) {
        return 18
    } else if (ageAtDisability >= 70 && ageAtDisability <= 71) {
        return 15
    }
    return 12
}

function dateDiffYears(dateTime1: DateTime, dateTime2: DateTime): number {
    return Math.abs(dateTime1.year - dateTime2.year)
}

function calcAgeAtBSDRule(benefit: Benefit, calculatedStartDate: DateTime) {
    return dateDiffYears(calculatedStartDate, benefit.getClaimantDateOfBirth())
}

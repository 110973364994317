import React, { ReactElement } from "react";
import "@pds-react/tab/dist/tab.min.css"
import currency from "currency.js";
import { Relationship } from "../../enums/Relationship";
import { Coverage } from "../../enums/Coverage";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import FlatTable from '../FlatTable';
import { useProduct } from "../../hooks/useProduct";
import { useCoverage } from "../../hooks/useCoverage";
import { BenefitsProps } from '../Benefits';
import BenefitsTab from "./BenefitsTab";
import { useMember } from "../../hooks/useMember";
import { Dependent } from "../../domain/Dependent";
import { PdsSegmentedControlItem, PdsSegmentedControl, PdsButton } from "@principal/design-system-react";
import { Member } from "../../domain/Member";
import { PdsTooltip } from "@principal/design-system-react";
import { PdsIconHelpCircle } from "@principal/design-system-icons-react";
import {DateTime} from "luxon";
import _ from "lodash";
import { AgeReductionType } from "../../generated/graphql";

interface LifeBenefit {
    coverage: string
    amount: string
}

enum LifeAmountType {
    FACE = 'Face',
    ACCIDENTAL_DEATH_AND_DISMEMBERMENT = 'Accidental death and dismemberment'
}

const LifeBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: memberLoading, data: member, error: memberError } = useMember()
    const { loading: productLoading, data: product, error: productError } = useProduct()

    enum AmountChoice {
        APPROVED = 'approved',
        PENDING = 'pending'
    }

    const amountChoiceByRelationship = new Map();
    for (const relationship of Object.values(Relationship)) {
        amountChoiceByRelationship.set(relationship, AmountChoice.APPROVED);
    }
    const [amountChoices, setAmountChoices] = React.useState(amountChoiceByRelationship);

    if(![Coverage.LIFE, Coverage.VOLUNTARY_TERM_LIFE].includes(coverage)) {
        return null;
    }

    return (
        <>{buildBenefits()}</>
    )

    function buildBenefits() {
        if(memberLoading || productLoading) {
            return <LoadingSpinner/>
        } else if(memberError || !member || productError || !product) {
            console.log('member error', memberError)
            console.log('product error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        return <>
            {buildBenefitTabs()}
        </>
    }

    function buildBenefitTabs() {
        const headers = ['Life benefits'];
        const relationships = Object.values(Relationship) as Relationship[];
        const sectionsByRelationship = new Map(relationships.map((r: Relationship) =>
            [
                r, [buildBenefitsFor(r)]
            ]
        ))
        return <BenefitsTab {...props} headers={headers} sectionsByRelationship={sectionsByRelationship} />;
    }

    function buildBenefitsFor(relationship: Relationship) {
        const memberOrDependents: (Member | Dependent)[] = Relationship.isDependent(relationship)
            ? member.dependents.filter((d: Dependent) => d.relationship === relationship)
            : [member]
        if(!memberOrDependents.length) {
            return null;
        }
        let benefits: LifeBenefit[] = [];
        if (Relationship.YOURSELF === relationship) {
            benefits = buildMemberBenefitsFor();
        } else if([Relationship.DOMESTIC_PARTNER, Relationship.SPOUSE].includes(relationship)) {
            benefits = buildSpouseBenefitsFor(memberOrDependents?.[0] as Dependent);
        } else if(Relationship.CHILD === relationship) {
            benefits = buildChildBenefitsFor(memberOrDependents);
        }
        return buildBenefitsTableFor('Life benefits', memberOrDependents[0], benefits);
    }

    function buildMemberBenefitsFor() {
        return buildAgeReducedBenefitsFor(member, product.configuration.ageReductions);
    }

    function buildSpouseBenefitsFor(spouse: Dependent) {
        const isVoluntaryTermLifeSpouse = product.spouse;
        return isVoluntaryTermLifeSpouse
            ? buildAgeReducedBenefitsFor(spouse, isVoluntaryTermLifeSpouse?.ageReductions)
            : [{
                coverage: 'Face amount',
                amount: findDependentAmountFor(product.dependent?.spouseAmount)
            }];
    }

    function buildAgeReducedBenefitsFor(memberOrDependent: Member | Dependent, productAgeReductions: AgeReductionType[]) {
        if(!memberOrDependent) {
            return [];
        }
        const memberAge = DateTime.now().diff(DateTime.fromISO(memberOrDependent.birthDate!!), 'years').years;
        const ageReductionsCopy = _.cloneDeep(productAgeReductions || []);
        const currentReductionPercentage = ageReductionsCopy
            .filter((a: AgeReductionType) => a.age && a.reductionPercentage && a.age <= memberAge)
            .reduce((a: number, c: AgeReductionType) => a + c.reductionPercentage!, 0);
        const { ageReductions } = ageReductionsCopy
            .sort((a: AgeReductionType, b: AgeReductionType) => a.age! - b.age!)
            .reduce((a: any, c: AgeReductionType) => {
                a.reductionPercentage += c.reductionPercentage;
                a.ageReductions.push({ age: c.age, reductionPercentage: a.reductionPercentage });
                return a;
            }, { ageReductions: [], reductionPercentage: 0 });
        let benefits = [{
            coverage: 'Face amount',
            amount: findAmountsFor(currentReductionPercentage, memberOrDependent, currentReductionPercentage)
        }, ...ageReductions
            .filter((a: AgeReductionType) => a.age! > memberAge)
            .map((a: AgeReductionType) => buildBenefitFor(a, memberOrDependent, currentReductionPercentage))];
        if (memberOrDependent.benefit.accidentalDeathAndDismembermentApprovedAmount || memberOrDependent.benefit.accidentalDeathAndDismembermentPendingAmount) {
            benefits = benefits.concat([{
                coverage: 'Accidental death and dismemberment amount',
                amount: findAmountsFor(currentReductionPercentage, memberOrDependent, currentReductionPercentage, LifeAmountType.ACCIDENTAL_DEATH_AND_DISMEMBERMENT)
            }, ...ageReductions
                .filter((a: AgeReductionType) => a.age! > memberAge)
                .map((a: AgeReductionType) => buildBenefitFor(a, memberOrDependent, currentReductionPercentage, LifeAmountType.ACCIDENTAL_DEATH_AND_DISMEMBERMENT))]);
        }
        return benefits;
    }

    function buildChildBenefitsFor(memberOrDependents: (Member | Dependent)[]) {
        const dependent = product.dependent;
        const childUnderSixMonths = dependent?.childUnderSixMonthsAmount;
        const hasChildUnderSixMonths = ![null, undefined].includes(childUnderSixMonths);
        const cadence = hasChildUnderSixMonths ? 'months' : 'days'
        const period = hasChildUnderSixMonths ? 6 : 14;
        const periodWord = `${period} ${cadence}`;
        const youngerChildren = memberOrDependents
            .filter((d: Member | Dependent) => d.birthDate && DateTime.now().diff(DateTime.fromISO(d.birthDate), cadence).as(cadence) < period)
            .map((d: Member | Dependent) => d.firstName)
            .join(', ');
        const olderChildren = memberOrDependents
            .filter((d: Member | Dependent) => d.birthDate && DateTime.now().diff(DateTime.fromISO(d.birthDate), cadence).as(cadence) >= period)
            .map((d: Member | Dependent) => d.firstName)
            .join(', ');
        return [{
            coverage: `Face amount for ${youngerChildren ? `${youngerChildren} and all other` : 'all'} children under ${periodWord} old`,
            amount: findDependentAmountFor(childUnderSixMonths ?? 1000)
        }, {
            coverage: `Face amount for ${olderChildren ? `${olderChildren} and all other` : 'all'} children ${periodWord} and older`,
            amount: hasChildUnderSixMonths ? findDependentAmountFor(dependent?.childSixMonthsAndOverAmount) : findAmountsFor(0, memberOrDependents?.[0], 0)
        }]
    }

    function buildBenefitsTableFor(title: string, memberOrDependent: Member | Dependent, benefits: LifeBenefit[]) {
        return <div className="flex-gap">
            {buildAmountControlFor(memberOrDependent)}
            <FlatTable title={title}
                       headers={['Coverage', 'Amount'].map(h => ({ header: h }))}
                       rows={benefits.map(b => {
                           return [
                               b.coverage,
                               b.amount,
                           ] as (string | ReactElement)[]
                       })}/>
        </div>;
    }

    function buildAmountControlFor(memberOrDependent: Member | Dependent) {
        if(!memberOrDependent.benefit.pendingAmount) {
            return;
        }
        const relationship = findRelationshipFor(memberOrDependent)
        const approvedAmounts = showApprovedAmounts(memberOrDependent);
        return <div className="flex-gap">
            <PdsSegmentedControl id={`amount-choice-${relationship}`}>
                <PdsSegmentedControlItem id={`approved-${relationship}`} onClick={() => amountChoiceOnClick(relationship, AmountChoice.APPROVED)} isSegmentActive={approvedAmounts} ariaLabel="Approved">Approved</PdsSegmentedControlItem>
                <PdsSegmentedControlItem id={`pending-${relationship}`} onClick={() => amountChoiceOnClick(relationship, AmountChoice.PENDING)} isSegmentActive={!approvedAmounts} ariaLabel="Pending">Pending</PdsSegmentedControlItem>
            </PdsSegmentedControl>
            <PdsTooltip placement='right'>
                <PdsButton slot="trigger" variant="icon" size="sm" ariaLabel="Proof of good health">
                    <PdsIconHelpCircle/>
                </PdsButton>
                Some of your benefit amounts are pending, requiring proof of good health. See your booklet for details.
            </PdsTooltip>
        </div>
    }

    function amountChoiceOnClick(relationship: Relationship, amountChoice: AmountChoice) {
        amountChoices.set(relationship, amountChoice)
        setAmountChoices(new Map(amountChoices));
    }

    function buildBenefitFor(ageReduction: AgeReductionType, memberOrDependent: Member | Dependent, currentReductionPercentage: number, amountType: LifeAmountType = LifeAmountType.FACE): LifeBenefit | null {
        return {
            coverage: `${amountType} amount at age ${ageReduction.age}`,
            amount: findAmountsFor(ageReduction.reductionPercentage!, memberOrDependent, currentReductionPercentage, amountType)
        };
    }

    function findAmountsFor(reductionPercentage: number, memberOrDependent: Member | Dependent, currentReductionPercentage: number, amountType: LifeAmountType = LifeAmountType.FACE): string {
        if(!memberOrDependent) {
            return '';
        }
        const benefit = memberOrDependent.benefit;
        const approvedAmount = LifeAmountType.FACE === amountType ? benefit.approvedAmount : benefit.accidentalDeathAndDismembermentApprovedAmount!!;
        const pendingAmount = LifeAmountType.FACE === amountType ? benefit.pendingAmount : benefit.accidentalDeathAndDismembermentPendingAmount!!;
        const approvedAmounts = showApprovedAmounts(memberOrDependent);
        const approvedOrPendingAmount = approvedAmounts ? approvedAmount : pendingAmount;
        const amount = approvedOrPendingAmount / (1 - currentReductionPercentage/100) * (1 - reductionPercentage/100)
        return currency(amount).format();
    }

    function findDependentAmountFor(amount: number) {
        return currency(member.benefit.approvedAmount > 0 ? amount : 0).format();
    }

    function showApprovedAmounts(memberOrDependent: Member | Dependent) {
        return AmountChoice.APPROVED === amountChoices.get(findRelationshipFor(memberOrDependent));
    }

    function findRelationshipFor(memberOrDependent: Member | Dependent) {
        return (memberOrDependent as Dependent).relationship || Relationship.YOURSELF;
    }
}

export default LifeBenefits;

import React, { ReactElement } from "react";
import "@pds-react/tab/dist/tab.min.css"
import currency from "currency.js";
import { Relationship } from "../../enums/Relationship";
import { snakeCaseToCapitalized } from "../../enums/FindKeyByValue";
import { Coverage } from "../../enums/Coverage";
import LoadingSpinner from "../../atoms/LoadingSpinner";
import FlatTable from '../FlatTable';
import { useProduct } from "../../hooks/useProduct";
import { useCoverage } from "../../hooks/useCoverage";
import { BenefitsProps } from '../Benefits';
import BenefitsTab from "./BenefitsTab";
import { AccidentBenefitPayable, AccidentBenefitScheduleGroup, AccidentBenefitScheduleGroupType } from "../../generated/graphql";

interface AccidentBenefitTable {
    headers: string[]
    benefits: AccidentBenefit[]
}

interface AccidentBenefit {
    coverage: string
    amounts: string[]
}

const AccidentBenefits = (props: BenefitsProps) => {
    const coverage = useCoverage();
    const { loading: productLoading, data: product, error: productError } = useProduct()

    if(coverage !== Coverage.ACCIDENT) {
        return null;
    }

    return (
        <>{buildBenefits()}</>
    )

    function buildBenefits() {
        if(productLoading) {
            return <LoadingSpinner/>
        } else if(productError || !product) {
            console.log('member group error', productError)
            return <p>Could not load member information, please try again later.</p>;
        }
        return <>
            {buildBenefitTabs()}
        </>
    }

    function buildBenefitTabs() {
        const benefitScheduleGroups = product.configuration.benefitSchedule.groups
        const headers = benefitScheduleGroups.map((g: AccidentBenefitScheduleGroup) => findGroupNameFor(g))
        const relationships = Object.values(Relationship) as Relationship[];
        const sectionsByRelationship = new Map(relationships.map((r: Relationship) =>
            [
                r, benefitScheduleGroups.map((g: AccidentBenefitScheduleGroup) => buildBenefitScheduleGroup(g, r))
            ]
        ))
        return <BenefitsTab {...props} headers={headers} sectionsByRelationship={sectionsByRelationship} />;
    }

    function buildBenefitScheduleGroup(benefitScheduleGroup: AccidentBenefitScheduleGroup, relationship: Relationship) {
        const benefits = buildBenefitsFor(benefitScheduleGroup, relationship)
        return buildBenefitsTableFor(findGroupNameFor(benefitScheduleGroup), benefits);
    }

    function buildBenefitsTableFor(title: string, benefits: AccidentBenefitTable) {
        return <div className="flex-gap">
            <FlatTable title={title}
                       headers={benefits.headers.map(h => ({ header: h }))}
                       rows={benefits.benefits.map(b => {
                               return [
                                   b.coverage,
                                   ...b.amounts,
                               ] as (string | ReactElement)[]
                           })}/>
        </div>;
    }

    function findGroupNameFor(benefitScheduleGroup: AccidentBenefitScheduleGroup) {
        return `${snakeCaseToCapitalized(benefitScheduleGroup.type)} benefits`;
    }

    function buildBenefitsFor(benefitScheduleGroup: AccidentBenefitScheduleGroup, relationship: Relationship): AccidentBenefitTable {
        const dislocationOrFracture = [AccidentBenefitScheduleGroupType.Dislocation, AccidentBenefitScheduleGroupType.Fracture]
            .includes(benefitScheduleGroup.type)
        return dislocationOrFracture
            ? buildDislocationOrFractureBenefitsFor(benefitScheduleGroup, relationship)
            : buildRegularBenefitsFor(benefitScheduleGroup, relationship);
    }

    function buildBenefitFor(benefitPayable: AccidentBenefitPayable, relationship: Relationship): AccidentBenefit {
        return {
            coverage: snakeCaseToCapitalized(benefitPayable.type),
            amounts: [findAmountFor(benefitPayable, relationship)],
        };
    }

    function buildDislocationOrFractureBenefitsFor(benefitScheduleGroup: AccidentBenefitScheduleGroup, relationship: Relationship) {
        const reductions = new Set<string>();
        const amountsByBodyPart = new Map<string, string[]>();
        [...benefitScheduleGroup.benefitsPayable]
            .sort((b1, b2) => {
                const benefit1 = parseDislocationOrFractureBenefit(benefitScheduleGroup, b1)
                const benefit2 = parseDislocationOrFractureBenefit(benefitScheduleGroup, b2)
                if(!benefit1 || !benefit2) {
                    return 0;
                }
                return benefit1.reduction?.localeCompare(benefit2.reduction)
            })
            .forEach((benefitPayable: AccidentBenefitPayable) => {
                const parsedBenefit = parseDislocationOrFractureBenefit(benefitScheduleGroup, benefitPayable)
                if(!parsedBenefit) {
                    return;
                }
                reductions.add(parsedBenefit.reduction)
                const bodyPart = parsedBenefit.bodyPart
                const existingBenefit = amountsByBodyPart.get(bodyPart) || [];
                existingBenefit.push(findAmountFor(benefitPayable, relationship))
                amountsByBodyPart.set(bodyPart, existingBenefit)
            })
        const combinedBenefits = [] as AccidentBenefit[];
        for (const [bodyPart, amounts] of amountsByBodyPart) {
            combinedBenefits.push({
                coverage: snakeCaseToCapitalized(`${benefitScheduleGroup.type}_${bodyPart}`),
                amounts
            })
        }
        const reductionHeaders = Array.from(reductions)
            .sort((r1, r2) => r1.localeCompare(r2))
            .map(r => snakeCaseToCapitalized(r));
        return {
            headers: ['Coverage', ...reductionHeaders],
            benefits: combinedBenefits.sort((b1, b2) => b1.coverage.localeCompare(b2.coverage))
        };
    }

    function parseDislocationOrFractureBenefit(benefitScheduleGroup: AccidentBenefitScheduleGroup, benefitPayable: AccidentBenefitPayable) {
        const typeRegex = new RegExp(`${benefitScheduleGroup.type}_(CLOSED_REDUCTION_CHIP|OPEN_REDUCTION_CHIP|CLOSED_REDUCTION_PARTIAL|OPEN_REDUCTION_PARTIAL|CLOSED_REDUCTION|OPEN_REDUCTION)_(.*)`, 'gi');
        const firstMatch = [...benefitPayable.type.matchAll(typeRegex)][0];
        if (firstMatch.length !== 3) {
            return null;
        }
        let reduction = firstMatch[1];
        let bodyPart = firstMatch[2];
        const withAnesthesia = '_WITH_ANESTHESIA';
        if(bodyPart.includes(withAnesthesia)) {
            reduction = reduction + withAnesthesia
            bodyPart = bodyPart.replace(withAnesthesia, '')
        }
        return {
            reduction,
            bodyPart
        }
    }

    function buildRegularBenefitsFor(benefitScheduleGroup: AccidentBenefitScheduleGroup, relationship: Relationship) {
        return {
            headers: ['Coverage', 'Amount'],
            benefits: benefitScheduleGroup.benefitsPayable.map(p => buildBenefitFor(p, relationship))
        };
    }

    function findAmountFor(benefitPayable: AccidentBenefitPayable, relationship: Relationship) {
        let amount = benefitPayable.employeeAmount
        if([Relationship.DOMESTIC_PARTNER, Relationship.SPOUSE].includes(relationship)) {
            amount = benefitPayable.spouseAmount
        } else if(Relationship.CHILD === relationship) {
            amount = benefitPayable.childAmount
        }
        const benefitAmount = currency(amount);
        return benefitAmount.value ? benefitAmount.format() : 'See policy';
    }
}

export default AccidentBenefits;

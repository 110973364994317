import { DateTime, DurationLike } from "luxon";
import { CadenceEnum, LongTermDisabilityPolicyCoverage, ShortTermDisabilityPolicyCoverage } from "../generated/graphql";

export function findPreExistingReviewStartDateFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                                  benefitEffectiveDate: DateTime, disabilityDate: DateTime,
                                                  lastTreatmentDate: DateTime): DateTime | null {
    if(doesNotHavePreExisting(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)) {
        return null;
    }
    const preExistingConditions = product.configuration.preExistingConditions;
    if(!preExistingConditions?.beforeEffectiveDatePeriod || !preExistingConditions.beforeEffectiveDateCadence) {
        return null;
    }
    return benefitEffectiveDate
        .minus(findDurationLikeFor(preExistingConditions.beforeEffectiveDatePeriod, preExistingConditions.beforeEffectiveDateCadence))
}

export function findPreExistingReviewEndDateFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                                benefitEffectiveDate: DateTime, disabilityDate: DateTime,
                                                lastTreatmentDate: DateTime): DateTime | null {
    if(doesNotHavePreExisting(product, benefitEffectiveDate, disabilityDate, lastTreatmentDate)) {
        return null;
    }
    return benefitEffectiveDate.minus({days: 1});
}

export function findTreatmentFreeStartDateFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage, lastTreatmentDate: DateTime): DateTime | null {
    const preExistingConditions = product.configuration.preExistingConditions
    if(!preExistingConditions?.included || !preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod || !preExistingConditions.afterEffectiveDateWithoutTreatmentCadence) {
        return null;
    }
    return lastTreatmentDate
        .minus(findDurationLikeFor(preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod, preExistingConditions.afterEffectiveDateWithoutTreatmentCadence));
}

function doesNotHavePreExisting(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                benefitEffectiveDate: DateTime, disabilityDate: DateTime, lastTreatmentDate: DateTime) {
    const preExistingConditions = product.configuration.preExistingConditions
    if(!preExistingConditions?.included || !preExistingConditions?.disabilityBeginsWithinPeriod || !preExistingConditions.disabilityBeginsWithinCadence) {
        return true;
    }
    const disabilityBeginsWithinDate = benefitEffectiveDate
        .plus(findDurationLikeFor(preExistingConditions.disabilityBeginsWithinPeriod, preExistingConditions.disabilityBeginsWithinCadence))
        .plus({days: 1});
    if(disabilityDate > disabilityBeginsWithinDate) {
        return true;
    }
    if(!preExistingConditions.afterEffectiveDateWithoutTreatmentCadence || !preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod) {
        return false;
    }
    const treatmentFreeStartDate = lastTreatmentDate
        .minus(findDurationLikeFor(preExistingConditions.afterEffectiveDateWithoutTreatmentPeriod, preExistingConditions.afterEffectiveDateWithoutTreatmentCadence))
    return treatmentFreeStartDate >= benefitEffectiveDate;
}

function findDurationLikeFor(period: number, cadence: CadenceEnum): DurationLike {
    return {
        [cadence.toLowerCase()]: period
    }
}

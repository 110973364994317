import { DateTime } from "luxon";
import { ConditionType } from "../enums/ConditionType";
import { LongTermDisabilityPolicyCoverage, ShortTermDisabilityPolicyCoverage } from "../generated/graphql";

export function findBenefitStartDateFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                        conditionType: ConditionType,
                                        disabilityDate: DateTime,
                                        hospitalizationDate: DateTime): DateTime {
    const shortTerm = product as ShortTermDisabilityPolicyCoverage;
    if(shortTerm.configuration.firstDayHospital && hospitalizationDate.isValid) {
        if (hospitalizationDate < disabilityDate) {
            return disabilityDate;
        }
        return hospitalizationDate;
    }
    const longTerm = product as LongTermDisabilityPolicyCoverage;
    const longTermConfiguration = longTerm.configuration;
    const eliminationPeriodMode = longTermConfiguration.eliminationPeriodMode;
    const eliminationPeriodBasis = eliminationPeriodMode
        ? eliminationPeriodMode.toLowerCase()
        : 'days'
    const eliminationPeriod = longTermConfiguration.eliminationPeriod;
    const eliminationPeriodTime = eliminationPeriod
        ? eliminationPeriod
        : findShortTermEliminationPeriodFor(shortTerm, conditionType);
    const startDateDayOffset = eliminationPeriod ? 0 : 1;
    return disabilityDate.plus({
        [eliminationPeriodBasis]: eliminationPeriodTime
    }).minus({'days': startDateDayOffset});
}

export function findEliminationPeriodFor(product: ShortTermDisabilityPolicyCoverage | LongTermDisabilityPolicyCoverage,
                                        conditionType: ConditionType, hospitalizationDate: DateTime) {
    const shortTerm = product as ShortTermDisabilityPolicyCoverage;
    const shortTermConfiguration = shortTerm.configuration;
    if(shortTermConfiguration.firstDayHospital && hospitalizationDate.isValid) {
        return 'the day you are hospitalized';
    }
    if(shortTermConfiguration.accidentEliminationPeriod) {
        return `${findShortTermEliminationPeriodFor(shortTerm, conditionType)} days`;
    }

    const longTerm = product as LongTermDisabilityPolicyCoverage;
    const longTermConfiguration = longTerm.configuration;
    return `${longTermConfiguration.eliminationPeriod} ${longTermConfiguration.eliminationPeriodMode.toLowerCase()}`
}

function findShortTermEliminationPeriodFor(product: ShortTermDisabilityPolicyCoverage, conditionType: ConditionType) {
    const configuration = product.configuration;
    return ConditionType.INJURY === conditionType ? configuration.accidentEliminationPeriod : configuration.sicknessEliminationPeriod
}